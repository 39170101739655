import React, { useEffect, useRef, useState } from "react"
import "./EventSettings.scss"
import Tab1 from "./EventsTabs/Tab1"
import Tab2 from "./EventsTabs/Tab2"
import Tab3 from "./EventsTabs/Tab3"
import { getEventsAbout } from "../../../api/api"
import Loader from "../../Loader/Loader"

const EventSettings = () => {
  const pageRef = useRef(null)
  const [fromTop, setFromTop] = useState(null)
  const [screen, setScreen] = useState(0)
  const [data, setData] = useState()
  const [type, setType] = useState()
  const [tab2data, setTab2data] = useState()

  const nextPage = () => {
    if (screen === 2) return
    setScreen((prev) => prev + 1)
  }

  const prevPage = () => {
    if (screen === 0) return
    setScreen((prev) => prev - 1)
  }

  const settingTabData = (newData) => {
    setTab2data(newData)
  }

  const pages = [
    <Tab1 nextPage={nextPage} data={data} />,
    <Tab2 nextPage={nextPage} data={data} nextPageData={settingTabData} />,
    <Tab3 data={data} newSetOfData={tab2data} />,
  ]

  const updateMaxHeight_EVENTSETTIGNS = () => {
    if (pageRef.current) {
      const distanceFromTop = pageRef.current.getBoundingClientRect().top
      const screenHeight = window.innerHeight
      const maxHeight = screenHeight - distanceFromTop - 16
      pageRef.current.style.maxHeight = `${maxHeight}px`
    }
  }

  useEffect(() => {
    updateMaxHeight_EVENTSETTIGNS()
    window.addEventListener("resize", updateMaxHeight_EVENTSETTIGNS)

    return () => {
      window.removeEventListener("resize", updateMaxHeight_EVENTSETTIGNS)
    }
  }, [])

  // data from api
  useEffect(() => {
    const setDataForEvents = async () => {
      const storedId = localStorage.getItem("eventId")
      if (storedId) {
        const res = await getEventsAbout(storedId)
        if (!res.error) {
          setData(res.body)
        }
      }
    }
    setDataForEvents()
  }, [])

  // handle height from top
  useEffect(() => {
    const storageElement = document.getElementById("album-settings")
    if (storageElement) {
      setFromTop(storageElement.getBoundingClientRect().top)
    } else {
      console.error("Element with ID 'event-settings' not found.")
    }
  }, [])

  // getting the event type
  useEffect(() => {
    const storedType = JSON.parse(localStorage.getItem("eventType"))
    setType(storedType)
  }, [])

  // helper from menu
  useEffect(() => {
    const editHelper = () => {
      const helperType = JSON.parse(
        sessionStorage.getItem("editThisEventForMe")
      )
      if (helperType === true) {
        setScreen(1)
        sessionStorage.removeItem("editThisEventForMe")
      }
    }
    editHelper()
  }, [])

  return (
    <div
      id="album-settings"
      className="table-box"
      ref={pageRef}
      style={{
        minHeight: `calc(100vh - ${fromTop}px - 10px)`,
        position: "relative",
        background: data && "#000",
        overflow: "scroll",
        margin: "10px",
        display: !data && "flex",
        justifyContent: !data && "center",
      }}
    >
      {!data ? (
        <div style={{ scale: "3" }}>
          <Loader />
        </div>
      ) : type === "WEBAPP_SUBSCRIPTION_EVENT" ? (
        <>{pages[1]}</>
      ) : (
        <>
          {screen > 0 && (
            <div
              onClick={prevPage}
              style={{
                position: "absolute",
                top: "15px",
                left: "15px",
                rotate: "90deg",
                cursor: "pointer",
              }}
            >
              <svg
                width="16"
                height="9"
                viewBox="0 0 16 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L8 8L15 1"
                  stroke="#E1E1E1"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          )}
          {pages[screen]}
        </>
      )}
    </div>
  )
}

export default EventSettings
